export const ROLE_ADMIN = 'admin';
export const ROLE_GESTOR = 'gestor';
export const ROLE_OPERADOR = 'operador';
export const ROLE_AGENTE_PEDAGOGICO = 'Agente Pedagógico';
export const ROLE_GESTOR_E_AGENTE_PEDAGOGICO = 'Gestor e Agente Pedagógico';
export const ROLE_GESTOR_DE_CAPTACAO = 'Gestor de Captação';
export const ROLE_OPERADOR_DE_CAPTACAO = 'Operador de Captação';
export const ROLE_GESTOR_DE_MARKETING = 'Gestor de Marketing';
export const ROLE_OPERADOR_DE_MARKETING = 'Operador de Marketing';
export const ROLE_AGENTE_PEDAGOGICO_DE_MARKETING =
  'Agente Pedagógico de Marketing';
export const ROLE_SUPERADMIN = 'superadmin';

export const REGISTERED_ROLES =
  '[admin, gestor, operador, Agente Pedagógico, Gestor e Agente Pedagógico, Gestor de Captação, Operador de Captação, Gestor de Marketing, Operador de Marketing, Agente Pedagógico de Marketing, superadmin]';

export const PERMISSION_AUTHENTICATION_ADD_ACCOUNT =
  'authentication.add_account';
export const PERMISSION_AUTHENTICATION_ADD_VERIFICATIONTOKEN =
  'authentication.add_verificationtoken';
export const PERMISSION_AUTHENTICATION_CHANGE_ACCOUNT =
  'authentication.change_account';
export const PERMISSION_AUTHENTICATION_CHANGE_VERIFICATIONTOKEN =
  'authentication.change_verificationtoken';
export const PERMISSION_AUTHENTICATION_DELETE_ACCOUNT =
  'authentication.delete_account';
export const PERMISSION_AUTHENTICATION_DELETE_VERIFICATIONTOKEN =
  'authentication.delete_verificationtoken';
export const PERMISSION_AUTHENTICATION_VIEW_ACCOUNT =
  'authentication.view_account';
export const PERMISSION_AUTHENTICATION_VIEW_VERIFICATIONTOKEN =
  'authentication.view_verificationtoken';
export const PERMISSION_AGENDA_ADD_AGENDAMENTO = 'agenda.add_agendamento';
export const PERMISSION_AGENDA_ADD_CALENDARAUTHENTICATION =
  'agenda.add_calendarauthentication';
export const PERMISSION_AGENDA_ADD_CALENDARIODISPONIVEL =
  'agenda.add_calendariodisponivel';
export const PERMISSION_AGENDA_ADD_CAMPODEVOLUTIVAVISITA =
  'agenda.add_campodevolutivavisita';
export const PERMISSION_AGENDA_ADD_DEVOLUTIVAVISITA =
  'agenda.add_devolutivavisita';
export const PERMISSION_AGENDA_ADD_DIADISPONIVEL = 'agenda.add_diadisponivel';
export const PERMISSION_AGENDA_ADD_ESCOLHAMULTIPLADEVOLUTIVAVISITA =
  'agenda.add_escolhamultipladevolutivavisita';
export const PERMISSION_AGENDA_ADD_ESCOLHAUNICADEVOLUTIVAVISITA =
  'agenda.add_escolhaunicadevolutivavisita';
export const PERMISSION_AGENDA_ADD_HORARIODISPONIVEL =
  'agenda.add_horariodisponivel';
export const PERMISSION_AGENDA_ADD_VALORCAMPODEVOLUTIVAVISITA =
  'agenda.add_valorcampodevolutivavisita';
export const PERMISSION_AGENDA_CHANGE_AGENDAMENTO = 'agenda.change_agendamento';
export const PERMISSION_AGENDA_CHANGE_CALENDARAUTHENTICATION =
  'agenda.change_calendarauthentication';
export const PERMISSION_AGENDA_CHANGE_CALENDARIODISPONIVEL =
  'agenda.change_calendariodisponivel';
export const PERMISSION_AGENDA_CHANGE_CAMPODEVOLUTIVAVISITA =
  'agenda.change_campodevolutivavisita';
export const PERMISSION_AGENDA_CHANGE_DEVOLUTIVAVISITA =
  'agenda.change_devolutivavisita';
export const PERMISSION_AGENDA_CHANGE_DIADISPONIVEL =
  'agenda.change_diadisponivel';
export const PERMISSION_AGENDA_CHANGE_ESCOLHAMULTIPLADEVOLUTIVAVISITA =
  'agenda.change_escolhamultipladevolutivavisita';
export const PERMISSION_AGENDA_CHANGE_ESCOLHAUNICADEVOLUTIVAVISITA =
  'agenda.change_escolhaunicadevolutivavisita';
export const PERMISSION_AGENDA_CHANGE_HORARIODISPONIVEL =
  'agenda.change_horariodisponivel';
export const PERMISSION_AGENDA_CHANGE_VALORCAMPODEVOLUTIVAVISITA =
  'agenda.change_valorcampodevolutivavisita';
export const PERMISSION_AGENDA_CONCLUIR_VISITA = 'agenda.concluir_visita';
export const PERMISSION_AGENDA_DELEGAR_VISITA = 'agenda.delegar_visita';
export const PERMISSION_AGENDA_DELETE_AGENDAMENTO = 'agenda.delete_agendamento';
export const PERMISSION_AGENDA_DELETE_CALENDARAUTHENTICATION =
  'agenda.delete_calendarauthentication';
export const PERMISSION_AGENDA_DELETE_CALENDARIODISPONIVEL =
  'agenda.delete_calendariodisponivel';
export const PERMISSION_AGENDA_DELETE_CAMPODEVOLUTIVAVISITA =
  'agenda.delete_campodevolutivavisita';
export const PERMISSION_AGENDA_DELETE_DEVOLUTIVAVISITA =
  'agenda.delete_devolutivavisita';
export const PERMISSION_AGENDA_DELETE_DIADISPONIVEL =
  'agenda.delete_diadisponivel';
export const PERMISSION_AGENDA_DELETE_ESCOLHAMULTIPLADEVOLUTIVAVISITA =
  'agenda.delete_escolhamultipladevolutivavisita';
export const PERMISSION_AGENDA_DELETE_ESCOLHAUNICADEVOLUTIVAVISITA =
  'agenda.delete_escolhaunicadevolutivavisita';
export const PERMISSION_AGENDA_DELETE_HORARIODISPONIVEL =
  'agenda.delete_horariodisponivel';
export const PERMISSION_AGENDA_DELETE_VALORCAMPODEVOLUTIVAVISITA =
  'agenda.delete_valorcampodevolutivavisita';
export const PERMISSION_AGENDA_REALIZAR_VISITA = 'agenda.realizar_visita';
export const PERMISSION_AGENDA_VIEW_AGENDAMENTO = 'agenda.view_agendamento';
export const PERMISSION_AGENDA_VIEW_CALENDARAUTHENTICATION =
  'agenda.view_calendarauthentication';
export const PERMISSION_AGENDA_VIEW_CALENDARIODISPONIVEL =
  'agenda.view_calendariodisponivel';
export const PERMISSION_AGENDA_VIEW_CAMPODEVOLUTIVAVISITA =
  'agenda.view_campodevolutivavisita';
export const PERMISSION_AGENDA_VIEW_DEVOLUTIVAVISITA =
  'agenda.view_devolutivavisita';
export const PERMISSION_AGENDA_VIEW_DIADISPONIVEL = 'agenda.view_diadisponivel';
export const PERMISSION_AGENDA_VIEW_ESCOLHAMULTIPLADEVOLUTIVAVISITA =
  'agenda.view_escolhamultipladevolutivavisita';
export const PERMISSION_AGENDA_VIEW_ESCOLHAUNICADEVOLUTIVAVISITA =
  'agenda.view_escolhaunicadevolutivavisita';
export const PERMISSION_AGENDA_VIEW_HORARIODISPONIVEL =
  'agenda.view_horariodisponivel';
export const PERMISSION_AGENDA_VIEW_VALORCAMPODEVOLUTIVAVISITA =
  'agenda.view_valorcampodevolutivavisita';
export const PERMISSION_CRM_ADD_ALUNO = 'crm.add_aluno';
export const PERMISSION_CRM_ADD_ANOTACAO = 'crm.add_anotacao';
export const PERMISSION_CRM_ADD_CAMPOPERSONALIZADO =
  'crm.add_campopersonalizado';
export const PERMISSION_CRM_ADD_CAMPOSESCOLHAMULTIPLA =
  'crm.add_camposescolhamultipla';
export const PERMISSION_CRM_ADD_CAMPOSESCOLHAUNICA =
  'crm.add_camposescolhaunica';
export const PERMISSION_CRM_ADD_COMOCONHECEUESCOLA =
  'crm.add_comoconheceuescola';
export const PERMISSION_CRM_ADD_ESCOLA = 'crm.add_escola';
export const PERMISSION_CRM_ADD_ESCOLADESTINO = 'crm.add_escoladestino';
export const PERMISSION_CRM_ADD_ETAPAFUNIL = 'crm.add_etapafunil';
export const PERMISSION_CRM_ADD_FUNIL = 'crm.add_funil';
export const PERMISSION_CRM_ADD_HISTORICOINTERACOES =
  'crm.add_historicointeracoes';
export const PERMISSION_CRM_ADD_JORNADA = 'crm.add_jornada';
export const PERMISSION_CRM_ADD_JORNADAETAPA = 'crm.add_jornadaetapa';
export const PERMISSION_CRM_ADD_MOTIVOCHURN = 'crm.add_motivochurn';
export const PERMISSION_CRM_ADD_ORIGEMLEAD = 'crm.add_origemlead';
export const PERMISSION_CRM_ADD_REGISTROCONVERSA = 'crm.add_registroconversa';
export const PERMISSION_CRM_ADD_RESPONSAVEL = 'crm.add_responsavel';
export const PERMISSION_CRM_ADD_TAREFA = 'crm.add_tarefa';
export const PERMISSION_CRM_ADD_TIPOTAREFA = 'crm.add_tipotarefa';
export const PERMISSION_CRM_ADD_TURMA = 'crm.add_turma';
export const PERMISSION_CRM_ADD_TURNO = 'crm.add_turno';
export const PERMISSION_CRM_ADD_USUARIOESCOLA = 'crm.add_usuarioescola';
export const PERMISSION_CRM_ADD_VALORCAMPOPERSONALIZADO =
  'crm.add_valorcampopersonalizado';
export const PERMISSION_CRM_CHANGE_ALUNO = 'crm.change_aluno';
export const PERMISSION_CRM_CHANGE_ANOTACAO = 'crm.change_anotacao';
export const PERMISSION_CRM_CHANGE_CAMPOPERSONALIZADO =
  'crm.change_campopersonalizado';
export const PERMISSION_CRM_CHANGE_CAMPOSESCOLHAMULTIPLA =
  'crm.change_camposescolhamultipla';
export const PERMISSION_CRM_CHANGE_CAMPOSESCOLHAUNICA =
  'crm.change_camposescolhaunica';
export const PERMISSION_CRM_CHANGE_COMOCONHECEUESCOLA =
  'crm.change_comoconheceuescola';
export const PERMISSION_CRM_CHANGE_ESCOLA = 'crm.change_escola';
export const PERMISSION_CRM_CHANGE_ESCOLADESTINO = 'crm.change_escoladestino';
export const PERMISSION_CRM_CHANGE_ETAPAFUNIL = 'crm.change_etapafunil';
export const PERMISSION_CRM_CHANGE_FUNIL = 'crm.change_funil';
export const PERMISSION_CRM_CHANGE_HISTORICOINTERACOES =
  'crm.change_historicointeracoes';
export const PERMISSION_CRM_CHANGE_JORNADA = 'crm.change_jornada';
export const PERMISSION_CRM_CHANGE_JORNADAETAPA = 'crm.change_jornadaetapa';
export const PERMISSION_CRM_CHANGE_MOTIVOCHURN = 'crm.change_motivochurn';
export const PERMISSION_CRM_CHANGE_ORIGEMLEAD = 'crm.change_origemlead';
export const PERMISSION_CRM_CHANGE_REGISTROCONVERSA =
  'crm.change_registroconversa';
export const PERMISSION_CRM_CHANGE_RESPONSAVEL = 'crm.change_responsavel';
export const PERMISSION_CRM_CHANGE_TAREFA = 'crm.change_tarefa';
export const PERMISSION_CRM_CHANGE_TIPOTAREFA = 'crm.change_tipotarefa';
export const PERMISSION_CRM_CHANGE_TURMA = 'crm.change_turma';
export const PERMISSION_CRM_CHANGE_TURNO = 'crm.change_turno';
export const PERMISSION_CRM_CHANGE_USUARIOESCOLA = 'crm.change_usuarioescola';
export const PERMISSION_CRM_CHANGE_VALORCAMPOPERSONALIZADO =
  'crm.change_valorcampopersonalizado';
export const PERMISSION_CRM_DELETE_ALUNO = 'crm.delete_aluno';
export const PERMISSION_CRM_DELETE_ANOTACAO = 'crm.delete_anotacao';
export const PERMISSION_CRM_DELETE_CAMPOPERSONALIZADO =
  'crm.delete_campopersonalizado';
export const PERMISSION_CRM_DELETE_CAMPOSESCOLHAMULTIPLA =
  'crm.delete_camposescolhamultipla';
export const PERMISSION_CRM_DELETE_CAMPOSESCOLHAUNICA =
  'crm.delete_camposescolhaunica';
export const PERMISSION_CRM_DELETE_COMOCONHECEUESCOLA =
  'crm.delete_comoconheceuescola';
export const PERMISSION_CRM_DELETE_ESCOLA = 'crm.delete_escola';
export const PERMISSION_CRM_DELETE_ESCOLADESTINO = 'crm.delete_escoladestino';
export const PERMISSION_CRM_DELETE_ETAPAFUNIL = 'crm.delete_etapafunil';
export const PERMISSION_CRM_DELETE_FUNIL = 'crm.delete_funil';
export const PERMISSION_CRM_DELETE_HISTORICOINTERACOES =
  'crm.delete_historicointeracoes';
export const PERMISSION_CRM_DELETE_JORNADA = 'crm.delete_jornada';
export const PERMISSION_CRM_DELETE_JORNADAETAPA = 'crm.delete_jornadaetapa';
export const PERMISSION_CRM_DELETE_MOTIVOCHURN = 'crm.delete_motivochurn';
export const PERMISSION_CRM_DELETE_ORIGEMLEAD = 'crm.delete_origemlead';
export const PERMISSION_CRM_DELETE_REGISTROCONVERSA =
  'crm.delete_registroconversa';
export const PERMISSION_CRM_DELETE_RESPONSAVEL = 'crm.delete_responsavel';
export const PERMISSION_CRM_DELETE_TAREFA = 'crm.delete_tarefa';
export const PERMISSION_CRM_DELETE_TIPOTAREFA = 'crm.delete_tipotarefa';
export const PERMISSION_CRM_DELETE_TURMA = 'crm.delete_turma';
export const PERMISSION_CRM_DELETE_TURNO = 'crm.delete_turno';
export const PERMISSION_CRM_DELETE_USUARIOESCOLA = 'crm.delete_usuarioescola';
export const PERMISSION_CRM_DELETE_VALORCAMPOPERSONALIZADO =
  'crm.delete_valorcampopersonalizado';
export const PERMISSION_CRM_LIST_ESCOLAS = 'crm.list_escolas';
export const PERMISSION_CRM_MANAGE_FUNIL = 'crm.manage_funil';
export const PERMISSION_CRM_VIEW_ALUNO = 'crm.view_aluno';
export const PERMISSION_CRM_VIEW_ANOTACAO = 'crm.view_anotacao';
export const PERMISSION_CRM_VIEW_CAMPOPERSONALIZADO =
  'crm.view_campopersonalizado';
export const PERMISSION_CRM_VIEW_CAMPOSESCOLHAMULTIPLA =
  'crm.view_camposescolhamultipla';
export const PERMISSION_CRM_VIEW_CAMPOSESCOLHAUNICA =
  'crm.view_camposescolhaunica';
export const PERMISSION_CRM_VIEW_COMOCONHECEUESCOLA =
  'crm.view_comoconheceuescola';
export const PERMISSION_CRM_VIEW_ESCOLA = 'crm.view_escola';
export const PERMISSION_CRM_VIEW_ESCOLADESTINO = 'crm.view_escoladestino';
export const PERMISSION_CRM_VIEW_ETAPAFUNIL = 'crm.view_etapafunil';
export const PERMISSION_CRM_VIEW_FUNIL = 'crm.view_funil';
export const PERMISSION_CRM_VIEW_HISTORICOINTERACOES =
  'crm.view_historicointeracoes';
export const PERMISSION_CRM_VIEW_JORNADA = 'crm.view_jornada';
export const PERMISSION_CRM_VIEW_JORNADAETAPA = 'crm.view_jornadaetapa';
export const PERMISSION_CRM_VIEW_MOTIVOCHURN = 'crm.view_motivochurn';
export const PERMISSION_CRM_VIEW_ORIGEMLEAD = 'crm.view_origemlead';
export const PERMISSION_CRM_VIEW_REGISTROCONVERSA = 'crm.view_registroconversa';
export const PERMISSION_CRM_VIEW_RESPONSAVEL = 'crm.view_responsavel';
export const PERMISSION_CRM_VIEW_TAREFA = 'crm.view_tarefa';
export const PERMISSION_CRM_VIEW_TIPOTAREFA = 'crm.view_tipotarefa';
export const PERMISSION_CRM_VIEW_TURMA = 'crm.view_turma';
export const PERMISSION_CRM_VIEW_TURNO = 'crm.view_turno';
export const PERMISSION_CRM_VIEW_USUARIOESCOLA = 'crm.view_usuarioescola';
export const PERMISSION_CRM_VIEW_VALORCAMPOPERSONALIZADO =
  'crm.view_valorcampopersonalizado';
export const PERMISSION_MARKETING_ADD_ANEXOSPEDIDOMARKETING =
  'marketing.add_anexospedidomarketing';
export const PERMISSION_MARKETING_ADD_CAMPANHA = 'marketing.add_campanha';
export const PERMISSION_MARKETING_ADD_PEDIDOMARKETING =
  'marketing.add_pedidomarketing';
export const PERMISSION_MARKETING_ADD_STATUSCADASTRADOPEDIDOMARKETING =
  'marketing.add_statuscadastradopedidomarketing';
export const PERMISSION_MARKETING_ADD_STATUSPEDIDOMARKETING =
  'marketing.add_statuspedidomarketing';
export const PERMISSION_MARKETING_ADD_TAREFAMARKETING =
  'marketing.add_tarefamarketing';
export const PERMISSION_MARKETING_CHANGE_ANEXOSPEDIDOMARKETING =
  'marketing.change_anexospedidomarketing';
export const PERMISSION_MARKETING_CHANGE_CAMPANHA = 'marketing.change_campanha';
export const PERMISSION_MARKETING_CHANGE_PEDIDOMARKETING =
  'marketing.change_pedidomarketing';
export const PERMISSION_MARKETING_CHANGE_STATUSCADASTRADOPEDIDOMARKETING =
  'marketing.change_statuscadastradopedidomarketing';
export const PERMISSION_MARKETING_CHANGE_STATUSPEDIDOMARKETING =
  'marketing.change_statuspedidomarketing';
export const PERMISSION_MARKETING_CHANGE_TAREFAMARKETING =
  'marketing.change_tarefamarketing';
export const PERMISSION_MARKETING_DELETE_ANEXOSPEDIDOMARKETING =
  'marketing.delete_anexospedidomarketing';
export const PERMISSION_MARKETING_DELETE_CAMPANHA = 'marketing.delete_campanha';
export const PERMISSION_MARKETING_DELETE_PEDIDOMARKETING =
  'marketing.delete_pedidomarketing';
export const PERMISSION_MARKETING_DELETE_STATUSCADASTRADOPEDIDOMARKETING =
  'marketing.delete_statuscadastradopedidomarketing';
export const PERMISSION_MARKETING_DELETE_STATUSPEDIDOMARKETING =
  'marketing.delete_statuspedidomarketing';
export const PERMISSION_MARKETING_DELETE_TAREFAMARKETING =
  'marketing.delete_tarefamarketing';
export const PERMISSION_MARKETING_VIEW_ANEXOSPEDIDOMARKETING =
  'marketing.view_anexospedidomarketing';
export const PERMISSION_MARKETING_VIEW_CAMPANHA = 'marketing.view_campanha';
export const PERMISSION_MARKETING_VIEW_KANBAN = 'marketing.view_kanban';
export const PERMISSION_MARKETING_VIEW_PEDIDOMARKETING =
  'marketing.view_pedidomarketing';
export const PERMISSION_MARKETING_VIEW_STATUSCADASTRADOPEDIDOMARKETING =
  'marketing.view_statuscadastradopedidomarketing';
export const PERMISSION_MARKETING_VIEW_STATUSPEDIDOMARKETING =
  'marketing.view_statuspedidomarketing';
export const PERMISSION_MARKETING_VIEW_TAREFAMARKETING =
  'marketing.view_tarefamarketing';
export const PERMISSION_USERS_ADD_BASEUSER = 'users.add_baseuser';
export const PERMISSION_USERS_ADD_INVITE = 'users.add_invite';
export const PERMISSION_USERS_CHANGE_BASEUSER = 'users.change_baseuser';
export const PERMISSION_USERS_CHANGE_INVITE = 'users.change_invite';
export const PERMISSION_USERS_DELETE_BASEUSER = 'users.delete_baseuser';
export const PERMISSION_USERS_DELETE_INVITE = 'users.delete_invite';
export const PERMISSION_USERS_LIST_BASEUSER = 'users.list_baseuser';
export const PERMISSION_USERS_LIST_INVITE = 'users.list_invite';
export const PERMISSION_USERS_UPDATE_USER_ROLE = 'users.update_user_role';
export const PERMISSION_USERS_UPDATE_USER_STATUS = 'users.update_user_status';
export const PERMISSION_USERS_VIEW_BASEUSER = 'users.view_baseuser';
export const PERMISSION_USERS_VIEW_INVITE = 'users.view_invite';
export const PERMISSION_TOKEN_BLACKLIST_ADD_BLACKLISTEDTOKEN =
  'token_blacklist.add_blacklistedtoken';
export const PERMISSION_TOKEN_BLACKLIST_ADD_OUTSTANDINGTOKEN =
  'token_blacklist.add_outstandingtoken';
export const PERMISSION_TOKEN_BLACKLIST_CHANGE_BLACKLISTEDTOKEN =
  'token_blacklist.change_blacklistedtoken';
export const PERMISSION_TOKEN_BLACKLIST_CHANGE_OUTSTANDINGTOKEN =
  'token_blacklist.change_outstandingtoken';
export const PERMISSION_TOKEN_BLACKLIST_DELETE_BLACKLISTEDTOKEN =
  'token_blacklist.delete_blacklistedtoken';
export const PERMISSION_TOKEN_BLACKLIST_DELETE_OUTSTANDINGTOKEN =
  'token_blacklist.delete_outstandingtoken';
export const PERMISSION_TOKEN_BLACKLIST_VIEW_BLACKLISTEDTOKEN =
  'token_blacklist.view_blacklistedtoken';
export const PERMISSION_TOKEN_BLACKLIST_VIEW_OUTSTANDINGTOKEN =
  'token_blacklist.view_outstandingtoken';
export const PERMISSION_CAPTACAO_ADD_BOTAOSITE = 'captacao.add_botaosite';
export const PERMISSION_CAPTACAO_ADD_BOTAOSTATUSURLSAUTORIZADAS =
  'captacao.add_botaostatusurlsautorizadas';
export const PERMISSION_CAPTACAO_ADD_CAMPOFORMULARIO =
  'captacao.add_campoformulario';
export const PERMISSION_CAPTACAO_ADD_FORMULARIO = 'captacao.add_formulario';
export const PERMISSION_CAPTACAO_ADD_LANDINGPAGE = 'captacao.add_landingpage';
export const PERMISSION_CAPTACAO_ADD_LANDINGPAGESTATUS =
  'captacao.add_landingpagestatus';
export const PERMISSION_CAPTACAO_ADD_MENSAGEMPADRONIZADA =
  'captacao.add_mensagempadronizada';
export const PERMISSION_CAPTACAO_ADD_METAS = 'captacao.add_metas';
export const PERMISSION_CAPTACAO_ADD_TIPOMENSAGEMPADRONIZADA =
  'captacao.add_tipomensagempadronizada';
export const PERMISSION_CAPTACAO_CHANGE_BOTAOSITE = 'captacao.change_botaosite';
export const PERMISSION_CAPTACAO_CHANGE_BOTAOSTATUSURLSAUTORIZADAS =
  'captacao.change_botaostatusurlsautorizadas';
export const PERMISSION_CAPTACAO_CHANGE_CAMPOFORMULARIO =
  'captacao.change_campoformulario';
export const PERMISSION_CAPTACAO_CHANGE_FORMULARIO =
  'captacao.change_formulario';
export const PERMISSION_CAPTACAO_CHANGE_LANDINGPAGE =
  'captacao.change_landingpage';
export const PERMISSION_CAPTACAO_CHANGE_LANDINGPAGESTATUS =
  'captacao.change_landingpagestatus';
export const PERMISSION_CAPTACAO_CHANGE_MENSAGEMPADRONIZADA =
  'captacao.change_mensagempadronizada';
export const PERMISSION_CAPTACAO_CHANGE_METAS = 'captacao.change_metas';
export const PERMISSION_CAPTACAO_CHANGE_TIPOMENSAGEMPADRONIZADA =
  'captacao.change_tipomensagempadronizada';
export const PERMISSION_CAPTACAO_DELETE_BOTAOSITE = 'captacao.delete_botaosite';
export const PERMISSION_CAPTACAO_DELETE_BOTAOSTATUSURLSAUTORIZADAS =
  'captacao.delete_botaostatusurlsautorizadas';
export const PERMISSION_CAPTACAO_DELETE_CAMPOFORMULARIO =
  'captacao.delete_campoformulario';
export const PERMISSION_CAPTACAO_DELETE_FORMULARIO =
  'captacao.delete_formulario';
export const PERMISSION_CAPTACAO_DELETE_LANDINGPAGE =
  'captacao.delete_landingpage';
export const PERMISSION_CAPTACAO_DELETE_LANDINGPAGESTATUS =
  'captacao.delete_landingpagestatus';
export const PERMISSION_CAPTACAO_DELETE_MENSAGEMPADRONIZADA =
  'captacao.delete_mensagempadronizada';
export const PERMISSION_CAPTACAO_DELETE_METAS = 'captacao.delete_metas';
export const PERMISSION_CAPTACAO_DELETE_TIPOMENSAGEMPADRONIZADA =
  'captacao.delete_tipomensagempadronizada';
export const PERMISSION_CAPTACAO_VIEW_BOTAOSITE = 'captacao.view_botaosite';
export const PERMISSION_CAPTACAO_VIEW_BOTAOSTATUSURLSAUTORIZADAS =
  'captacao.view_botaostatusurlsautorizadas';
export const PERMISSION_CAPTACAO_VIEW_CAMPOFORMULARIO =
  'captacao.view_campoformulario';
export const PERMISSION_CAPTACAO_VIEW_FORMULARIO = 'captacao.view_formulario';
export const PERMISSION_CAPTACAO_VIEW_LANDINGPAGE = 'captacao.view_landingpage';
export const PERMISSION_CAPTACAO_VIEW_LANDINGPAGESTATUS =
  'captacao.view_landingpagestatus';
export const PERMISSION_CAPTACAO_VIEW_MENSAGEMPADRONIZADA =
  'captacao.view_mensagempadronizada';
export const PERMISSION_CAPTACAO_VIEW_METAS = 'captacao.view_metas';
export const PERMISSION_CAPTACAO_VIEW_TIPOMENSAGEMPADRONIZADA =
  'captacao.view_tipomensagempadronizada';
export const PERMISSION_WHATSAPP_ADD_CHATBOT = 'whatsapp.add_chatbot';
export const PERMISSION_WHATSAPP_ADD_CONTATO = 'whatsapp.add_contato';
export const PERMISSION_WHATSAPP_ADD_CONVERSATION = 'whatsapp.add_conversation';
export const PERMISSION_WHATSAPP_ADD_DIADISPONIVEL =
  'whatsapp.add_diadisponivel';
export const PERMISSION_WHATSAPP_ADD_HORARIOATENDIMENTO =
  'whatsapp.add_horarioatendimento';
export const PERMISSION_WHATSAPP_ADD_HORARIODISPONIVEL =
  'whatsapp.add_horariodisponivel';
export const PERMISSION_WHATSAPP_ADD_MESSAGE = 'whatsapp.add_message';
export const PERMISSION_WHATSAPP_ADD_USERWHATSAPPAUTH =
  'whatsapp.add_userwhatsappauth';
export const PERMISSION_WHATSAPP_ADD_USERWHATSAPPPHONE =
  'whatsapp.add_userwhatsappphone';
export const PERMISSION_WHATSAPP_ADD_WHATSAPPAUTH = 'whatsapp.add_whatsappauth';
export const PERMISSION_WHATSAPP_ADD_WHATSAPPBUSINESSACCOUNT =
  'whatsapp.add_whatsappbusinessaccount';
export const PERMISSION_WHATSAPP_ADD_WHATSAPPPHONE =
  'whatsapp.add_whatsappphone';
export const PERMISSION_WHATSAPP_CHANGE_CHATBOT = 'whatsapp.change_chatbot';
export const PERMISSION_WHATSAPP_CHANGE_CONTATO = 'whatsapp.change_contato';
export const PERMISSION_WHATSAPP_CHANGE_CONVERSATION =
  'whatsapp.change_conversation';
export const PERMISSION_WHATSAPP_CHANGE_DIADISPONIVEL =
  'whatsapp.change_diadisponivel';
export const PERMISSION_WHATSAPP_CHANGE_HORARIOATENDIMENTO =
  'whatsapp.change_horarioatendimento';
export const PERMISSION_WHATSAPP_CHANGE_HORARIODISPONIVEL =
  'whatsapp.change_horariodisponivel';
export const PERMISSION_WHATSAPP_CHANGE_MESSAGE = 'whatsapp.change_message';
export const PERMISSION_WHATSAPP_CHANGE_USERWHATSAPPAUTH =
  'whatsapp.change_userwhatsappauth';
export const PERMISSION_WHATSAPP_CHANGE_USERWHATSAPPPHONE =
  'whatsapp.change_userwhatsappphone';
export const PERMISSION_WHATSAPP_CHANGE_WHATSAPPAUTH =
  'whatsapp.change_whatsappauth';
export const PERMISSION_WHATSAPP_CHANGE_WHATSAPPBUSINESSACCOUNT =
  'whatsapp.change_whatsappbusinessaccount';
export const PERMISSION_WHATSAPP_CHANGE_WHATSAPPPHONE =
  'whatsapp.change_whatsappphone';
export const PERMISSION_WHATSAPP_DELETE_CHATBOT = 'whatsapp.delete_chatbot';
export const PERMISSION_WHATSAPP_DELETE_CONTATO = 'whatsapp.delete_contato';
export const PERMISSION_WHATSAPP_DELETE_CONVERSATION =
  'whatsapp.delete_conversation';
export const PERMISSION_WHATSAPP_DELETE_DIADISPONIVEL =
  'whatsapp.delete_diadisponivel';
export const PERMISSION_WHATSAPP_DELETE_HORARIOATENDIMENTO =
  'whatsapp.delete_horarioatendimento';
export const PERMISSION_WHATSAPP_DELETE_HORARIODISPONIVEL =
  'whatsapp.delete_horariodisponivel';
export const PERMISSION_WHATSAPP_DELETE_MESSAGE = 'whatsapp.delete_message';
export const PERMISSION_WHATSAPP_DELETE_USERWHATSAPPAUTH =
  'whatsapp.delete_userwhatsappauth';
export const PERMISSION_WHATSAPP_DELETE_USERWHATSAPPPHONE =
  'whatsapp.delete_userwhatsappphone';
export const PERMISSION_WHATSAPP_DELETE_WHATSAPPAUTH =
  'whatsapp.delete_whatsappauth';
export const PERMISSION_WHATSAPP_DELETE_WHATSAPPBUSINESSACCOUNT =
  'whatsapp.delete_whatsappbusinessaccount';
export const PERMISSION_WHATSAPP_DELETE_WHATSAPPPHONE =
  'whatsapp.delete_whatsappphone';
export const PERMISSION_WHATSAPP_VIEW_CHATBOT = 'whatsapp.view_chatbot';
export const PERMISSION_WHATSAPP_VIEW_CONTATO = 'whatsapp.view_contato';
export const PERMISSION_WHATSAPP_VIEW_CONVERSATION =
  'whatsapp.view_conversation';
export const PERMISSION_WHATSAPP_VIEW_DIADISPONIVEL =
  'whatsapp.view_diadisponivel';
export const PERMISSION_WHATSAPP_VIEW_HORARIOATENDIMENTO =
  'whatsapp.view_horarioatendimento';
export const PERMISSION_WHATSAPP_VIEW_HORARIODISPONIVEL =
  'whatsapp.view_horariodisponivel';
export const PERMISSION_WHATSAPP_VIEW_MESSAGE = 'whatsapp.view_message';
export const PERMISSION_WHATSAPP_VIEW_USERWHATSAPPAUTH =
  'whatsapp.view_userwhatsappauth';
export const PERMISSION_WHATSAPP_VIEW_USERWHATSAPPPHONE =
  'whatsapp.view_userwhatsappphone';
export const PERMISSION_WHATSAPP_VIEW_WHATSAPPAUTH =
  'whatsapp.view_whatsappauth';
export const PERMISSION_WHATSAPP_VIEW_WHATSAPPBUSINESSACCOUNT =
  'whatsapp.view_whatsappbusinessaccount';
export const PERMISSION_WHATSAPP_VIEW_WHATSAPPPHONE =
  'whatsapp.view_whatsappphone';
export const PERMISSION_DJANGO_CELERY_RESULTS_ADD_CHORDCOUNTER =
  'django_celery_results.add_chordcounter';
export const PERMISSION_DJANGO_CELERY_RESULTS_ADD_GROUPRESULT =
  'django_celery_results.add_groupresult';
export const PERMISSION_DJANGO_CELERY_RESULTS_ADD_TASKRESULT =
  'django_celery_results.add_taskresult';
export const PERMISSION_DJANGO_CELERY_RESULTS_CHANGE_CHORDCOUNTER =
  'django_celery_results.change_chordcounter';
export const PERMISSION_DJANGO_CELERY_RESULTS_CHANGE_GROUPRESULT =
  'django_celery_results.change_groupresult';
export const PERMISSION_DJANGO_CELERY_RESULTS_CHANGE_TASKRESULT =
  'django_celery_results.change_taskresult';
export const PERMISSION_DJANGO_CELERY_RESULTS_DELETE_CHORDCOUNTER =
  'django_celery_results.delete_chordcounter';
export const PERMISSION_DJANGO_CELERY_RESULTS_DELETE_GROUPRESULT =
  'django_celery_results.delete_groupresult';
export const PERMISSION_DJANGO_CELERY_RESULTS_DELETE_TASKRESULT =
  'django_celery_results.delete_taskresult';
export const PERMISSION_DJANGO_CELERY_RESULTS_VIEW_CHORDCOUNTER =
  'django_celery_results.view_chordcounter';
export const PERMISSION_DJANGO_CELERY_RESULTS_VIEW_GROUPRESULT =
  'django_celery_results.view_groupresult';
export const PERMISSION_DJANGO_CELERY_RESULTS_VIEW_TASKRESULT =
  'django_celery_results.view_taskresult';
export const PERMISSION_DJANGO_CELERY_BEAT_ADD_CLOCKEDSCHEDULE =
  'django_celery_beat.add_clockedschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_ADD_CRONTABSCHEDULE =
  'django_celery_beat.add_crontabschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_ADD_INTERVALSCHEDULE =
  'django_celery_beat.add_intervalschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_ADD_PERIODICTASK =
  'django_celery_beat.add_periodictask';
export const PERMISSION_DJANGO_CELERY_BEAT_ADD_PERIODICTASKS =
  'django_celery_beat.add_periodictasks';
export const PERMISSION_DJANGO_CELERY_BEAT_ADD_SOLARSCHEDULE =
  'django_celery_beat.add_solarschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_CHANGE_CLOCKEDSCHEDULE =
  'django_celery_beat.change_clockedschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_CHANGE_CRONTABSCHEDULE =
  'django_celery_beat.change_crontabschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_CHANGE_INTERVALSCHEDULE =
  'django_celery_beat.change_intervalschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_CHANGE_PERIODICTASK =
  'django_celery_beat.change_periodictask';
export const PERMISSION_DJANGO_CELERY_BEAT_CHANGE_PERIODICTASKS =
  'django_celery_beat.change_periodictasks';
export const PERMISSION_DJANGO_CELERY_BEAT_CHANGE_SOLARSCHEDULE =
  'django_celery_beat.change_solarschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_DELETE_CLOCKEDSCHEDULE =
  'django_celery_beat.delete_clockedschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_DELETE_CRONTABSCHEDULE =
  'django_celery_beat.delete_crontabschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_DELETE_INTERVALSCHEDULE =
  'django_celery_beat.delete_intervalschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_DELETE_PERIODICTASK =
  'django_celery_beat.delete_periodictask';
export const PERMISSION_DJANGO_CELERY_BEAT_DELETE_PERIODICTASKS =
  'django_celery_beat.delete_periodictasks';
export const PERMISSION_DJANGO_CELERY_BEAT_DELETE_SOLARSCHEDULE =
  'django_celery_beat.delete_solarschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_VIEW_CLOCKEDSCHEDULE =
  'django_celery_beat.view_clockedschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_VIEW_CRONTABSCHEDULE =
  'django_celery_beat.view_crontabschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_VIEW_INTERVALSCHEDULE =
  'django_celery_beat.view_intervalschedule';
export const PERMISSION_DJANGO_CELERY_BEAT_VIEW_PERIODICTASK =
  'django_celery_beat.view_periodictask';
export const PERMISSION_DJANGO_CELERY_BEAT_VIEW_PERIODICTASKS =
  'django_celery_beat.view_periodictasks';
export const PERMISSION_DJANGO_CELERY_BEAT_VIEW_SOLARSCHEDULE =
  'django_celery_beat.view_solarschedule';
export const PERMISSION_CONTENTTYPES_ADD_CONTENTTYPE =
  'contenttypes.add_contenttype';
export const PERMISSION_CONTENTTYPES_CHANGE_CONTENTTYPE =
  'contenttypes.change_contenttype';
export const PERMISSION_CONTENTTYPES_DELETE_CONTENTTYPE =
  'contenttypes.delete_contenttype';
export const PERMISSION_CONTENTTYPES_VIEW_CONTENTTYPE =
  'contenttypes.view_contenttype';
export const PERMISSION_EMAILS_ADD_EMAIL = 'emails.add_email';
export const PERMISSION_EMAILS_ADD_EMAILTEMPLATE = 'emails.add_emailtemplate';
export const PERMISSION_EMAILS_CHANGE_EMAIL = 'emails.change_email';
export const PERMISSION_EMAILS_CHANGE_EMAILTEMPLATE =
  'emails.change_emailtemplate';
export const PERMISSION_EMAILS_DELETE_EMAIL = 'emails.delete_email';
export const PERMISSION_EMAILS_DELETE_EMAILTEMPLATE =
  'emails.delete_emailtemplate';
export const PERMISSION_EMAILS_VIEW_EMAIL = 'emails.view_email';
export const PERMISSION_EMAILS_VIEW_EMAILTEMPLATE = 'emails.view_emailtemplate';
export const PERMISSION_KANBAN_ADD_ETAPA = 'kanban.add_etapa';
export const PERMISSION_KANBAN_ADD_TICKET = 'kanban.add_ticket';
export const PERMISSION_KANBAN_CHANGE_ETAPA = 'kanban.change_etapa';
export const PERMISSION_KANBAN_CHANGE_TICKET = 'kanban.change_ticket';
export const PERMISSION_KANBAN_DELETE_ETAPA = 'kanban.delete_etapa';
export const PERMISSION_KANBAN_DELETE_TICKET = 'kanban.delete_ticket';
export const PERMISSION_KANBAN_VIEW_ETAPA = 'kanban.view_etapa';
export const PERMISSION_KANBAN_VIEW_TICKET = 'kanban.view_ticket';
export const PERMISSION_FILES_ADD_FILE = 'files.add_file';
export const PERMISSION_FILES_CHANGE_FILE = 'files.change_file';
export const PERMISSION_FILES_DELETE_FILE = 'files.delete_file';
export const PERMISSION_FILES_VIEW_FILE = 'files.view_file';
export const PERMISSION_AUTH_ADD_GROUP = 'auth.add_group';
export const PERMISSION_AUTH_ADD_PERMISSION = 'auth.add_permission';
export const PERMISSION_AUTH_CHANGE_GROUP = 'auth.change_group';
export const PERMISSION_AUTH_CHANGE_PERMISSION = 'auth.change_permission';
export const PERMISSION_AUTH_DELETE_GROUP = 'auth.delete_group';
export const PERMISSION_AUTH_DELETE_PERMISSION = 'auth.delete_permission';
export const PERMISSION_AUTH_VIEW_GROUP = 'auth.view_group';
export const PERMISSION_AUTH_VIEW_PERMISSION = 'auth.view_permission';
export const PERMISSION_HISTORICO_ADD_HISTORICOJORNADA =
  'historico.add_historicojornada';
export const PERMISSION_HISTORICO_ADD_HISTORICOUSER =
  'historico.add_historicouser';
export const PERMISSION_HISTORICO_CHANGE_HISTORICOJORNADA =
  'historico.change_historicojornada';
export const PERMISSION_HISTORICO_CHANGE_HISTORICOUSER =
  'historico.change_historicouser';
export const PERMISSION_HISTORICO_DELETE_HISTORICOJORNADA =
  'historico.delete_historicojornada';
export const PERMISSION_HISTORICO_DELETE_HISTORICOUSER =
  'historico.delete_historicouser';
export const PERMISSION_HISTORICO_VIEW_HISTORICOJORNADA =
  'historico.view_historicojornada';
export const PERMISSION_HISTORICO_VIEW_HISTORICOUSER =
  'historico.view_historicouser';
export const PERMISSION_LOGGER_ADD_LOG = 'logger.add_log';
export const PERMISSION_LOGGER_CHANGE_LOG = 'logger.change_log';
export const PERMISSION_LOGGER_DELETE_LOG = 'logger.delete_log';
export const PERMISSION_LOGGER_VIEW_LOG = 'logger.view_log';
export const PERMISSION_ADMIN_ADD_LOGENTRY = 'admin.add_logentry';
export const PERMISSION_ADMIN_CHANGE_LOGENTRY = 'admin.change_logentry';
export const PERMISSION_ADMIN_DELETE_LOGENTRY = 'admin.delete_logentry';
export const PERMISSION_ADMIN_VIEW_LOGENTRY = 'admin.view_logentry';
export const PERMISSION_NOTIFICATIONS_ADD_NOTIFICATION =
  'notifications.add_notification';
export const PERMISSION_NOTIFICATIONS_ADD_NOTIFICATIONXUSERCONFIG =
  'notifications.add_notificationxuserconfig';
export const PERMISSION_NOTIFICATIONS_ADD_USERNOTIFICATIONCONFIG =
  'notifications.add_usernotificationconfig';
export const PERMISSION_NOTIFICATIONS_CHANGE_NOTIFICATION =
  'notifications.change_notification';
export const PERMISSION_NOTIFICATIONS_CHANGE_NOTIFICATIONXUSERCONFIG =
  'notifications.change_notificationxuserconfig';
export const PERMISSION_NOTIFICATIONS_CHANGE_USERNOTIFICATIONCONFIG =
  'notifications.change_usernotificationconfig';
export const PERMISSION_NOTIFICATIONS_DELETE_NOTIFICATION =
  'notifications.delete_notification';
export const PERMISSION_NOTIFICATIONS_DELETE_NOTIFICATIONXUSERCONFIG =
  'notifications.delete_notificationxuserconfig';
export const PERMISSION_NOTIFICATIONS_DELETE_USERNOTIFICATIONCONFIG =
  'notifications.delete_usernotificationconfig';
export const PERMISSION_NOTIFICATIONS_VIEW_NOTIFICATION =
  'notifications.view_notification';
export const PERMISSION_NOTIFICATIONS_VIEW_NOTIFICATIONXUSERCONFIG =
  'notifications.view_notificationxuserconfig';
export const PERMISSION_NOTIFICATIONS_VIEW_USERNOTIFICATIONCONFIG =
  'notifications.view_usernotificationconfig';
export const PERMISSION_TESTING_EXAMPLES_ADD_ROSTER =
  'testing_examples.add_roster';
export const PERMISSION_TESTING_EXAMPLES_ADD_SCHOOL =
  'testing_examples.add_school';
export const PERMISSION_TESTING_EXAMPLES_ADD_SCHOOLCOURSE =
  'testing_examples.add_schoolcourse';
export const PERMISSION_TESTING_EXAMPLES_ADD_STUDENT =
  'testing_examples.add_student';
export const PERMISSION_TESTING_EXAMPLES_CHANGE_ROSTER =
  'testing_examples.change_roster';
export const PERMISSION_TESTING_EXAMPLES_CHANGE_SCHOOL =
  'testing_examples.change_school';
export const PERMISSION_TESTING_EXAMPLES_CHANGE_SCHOOLCOURSE =
  'testing_examples.change_schoolcourse';
export const PERMISSION_TESTING_EXAMPLES_CHANGE_STUDENT =
  'testing_examples.change_student';
export const PERMISSION_TESTING_EXAMPLES_DELETE_ROSTER =
  'testing_examples.delete_roster';
export const PERMISSION_TESTING_EXAMPLES_DELETE_SCHOOL =
  'testing_examples.delete_school';
export const PERMISSION_TESTING_EXAMPLES_DELETE_SCHOOLCOURSE =
  'testing_examples.delete_schoolcourse';
export const PERMISSION_TESTING_EXAMPLES_DELETE_STUDENT =
  'testing_examples.delete_student';
export const PERMISSION_TESTING_EXAMPLES_VIEW_ROSTER =
  'testing_examples.view_roster';
export const PERMISSION_TESTING_EXAMPLES_VIEW_SCHOOL =
  'testing_examples.view_school';
export const PERMISSION_TESTING_EXAMPLES_VIEW_SCHOOLCOURSE =
  'testing_examples.view_schoolcourse';
export const PERMISSION_TESTING_EXAMPLES_VIEW_STUDENT =
  'testing_examples.view_student';
export const PERMISSION_SESSIONS_ADD_SESSION = 'sessions.add_session';
export const PERMISSION_SESSIONS_CHANGE_SESSION = 'sessions.change_session';
export const PERMISSION_SESSIONS_DELETE_SESSION = 'sessions.delete_session';
export const PERMISSION_SESSIONS_VIEW_SESSION = 'sessions.view_session';
export const PERMISSION_DB_ADD_TESTMODEL = 'db.add_testmodel';
export const PERMISSION_DB_CHANGE_TESTMODEL = 'db.change_testmodel';
export const PERMISSION_DB_DELETE_TESTMODEL = 'db.delete_testmodel';
export const PERMISSION_DB_VIEW_TESTMODEL = 'db.view_testmodel';

export const PERMISSION_GROUP_MAP = {
  'admin.add_logentry': ['admin', 'superadmin'],
  'admin.change_logentry': ['admin', 'superadmin'],
  'admin.delete_logentry': ['admin', 'superadmin'],
  'admin.view_logentry': ['admin', 'superadmin'],
  'agenda.add_agendamento': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'superadmin',
  ],
  'agenda.add_calendarauthentication': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
  ],
  'agenda.add_calendariodisponivel': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
  ],
  'agenda.add_campodevolutivavisita': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'admin',
    'superadmin',
  ],
  'agenda.add_devolutivavisita': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'superadmin',
  ],
  'agenda.add_diadisponivel': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
  ],
  'agenda.add_escolhamultipladevolutivavisita': [],
  'agenda.add_escolhaunicadevolutivavisita': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'admin',
    'superadmin',
  ],
  'agenda.add_horariodisponivel': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
  ],
  'agenda.add_valorcampodevolutivavisita': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
  ],
  'agenda.change_agendamento': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'superadmin',
  ],
  'agenda.change_calendarauthentication': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
  ],
  'agenda.change_calendariodisponivel': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
  ],
  'agenda.change_campodevolutivavisita': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
  ],
  'agenda.change_devolutivavisita': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'superadmin',
  ],
  'agenda.change_diadisponivel': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
  ],
  'agenda.change_escolhamultipladevolutivavisita': [],
  'agenda.change_escolhaunicadevolutivavisita': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'admin',
    'superadmin',
  ],
  'agenda.change_horariodisponivel': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
  ],
  'agenda.change_valorcampodevolutivavisita': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'superadmin',
  ],
  'agenda.concluir_visita': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'superadmin',
  ],
  'agenda.delegar_visita': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'superadmin',
  ],
  'agenda.delete_agendamento': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'superadmin',
  ],
  'agenda.delete_calendarauthentication': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
  ],
  'agenda.delete_calendariodisponivel': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
  ],
  'agenda.delete_campodevolutivavisita': ['Gestor e Agente Pedag\u00f3gico'],
  'agenda.delete_devolutivavisita': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
  ],
  'agenda.delete_diadisponivel': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
  ],
  'agenda.delete_escolhamultipladevolutivavisita': [],
  'agenda.delete_escolhaunicadevolutivavisita': [
    'Gestor e Agente Pedag\u00f3gico',
  ],
  'agenda.delete_horariodisponivel': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
  ],
  'agenda.delete_valorcampodevolutivavisita': [
    'Agente Pedag\u00f3gico',
    'Gestor e Agente Pedag\u00f3gico',
  ],
  'agenda.realizar_visita': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
  ],
  'agenda.view_agendamento': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'superadmin',
  ],
  'agenda.view_calendarauthentication': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'superadmin',
  ],
  'agenda.view_calendariodisponivel': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'superadmin',
  ],
  'agenda.view_campodevolutivavisita': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'superadmin',
  ],
  'agenda.view_devolutivavisita': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'superadmin',
  ],
  'agenda.view_diadisponivel': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
  ],
  'agenda.view_escolhamultipladevolutivavisita': [],
  'agenda.view_escolhaunicadevolutivavisita': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'superadmin',
  ],
  'agenda.view_horariodisponivel': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
  ],
  'agenda.view_valorcampodevolutivavisita': [
    'Agente Pedag\u00f3gico',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'superadmin',
  ],
  'auth.add_group': ['admin', 'superadmin'],
  'auth.add_permission': ['admin', 'superadmin'],
  'auth.change_group': ['admin', 'superadmin'],
  'auth.change_permission': ['admin', 'superadmin'],
  'auth.delete_group': ['admin', 'superadmin'],
  'auth.delete_permission': ['admin', 'superadmin'],
  'auth.view_group': ['admin', 'superadmin'],
  'auth.view_permission': ['admin', 'superadmin'],
  'authentication.add_account': ['admin', 'superadmin'],
  'authentication.add_verificationtoken': [],
  'authentication.change_account': ['admin', 'superadmin'],
  'authentication.change_verificationtoken': [],
  'authentication.delete_account': ['admin', 'superadmin'],
  'authentication.delete_verificationtoken': [],
  'authentication.view_account': ['admin', 'superadmin'],
  'authentication.view_verificationtoken': [],
  'captacao.add_botaosite': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor de Marketing',
    'Gestor e Agente Pedag\u00f3gico',
    'admin',
    'superadmin',
  ],
  'captacao.add_botaostatusurlsautorizadas': [],
  'captacao.add_campoformulario': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor de Marketing',
    'Gestor e Agente Pedag\u00f3gico',
    'admin',
    'superadmin',
  ],
  'captacao.add_formulario': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor de Marketing',
    'Gestor e Agente Pedag\u00f3gico',
    'admin',
    'superadmin',
  ],
  'captacao.add_landingpage': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor de Marketing',
    'Gestor e Agente Pedag\u00f3gico',
    'admin',
    'superadmin',
  ],
  'captacao.add_landingpagestatus': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor de Marketing',
    'Gestor e Agente Pedag\u00f3gico',
    'admin',
    'superadmin',
  ],
  'captacao.add_mensagempadronizada': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'admin',
    'superadmin',
  ],
  'captacao.add_metas': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'admin',
    'superadmin',
  ],
  'captacao.add_tipomensagempadronizada': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'admin',
    'superadmin',
  ],
  'captacao.change_botaosite': ['admin', 'superadmin'],
  'captacao.change_botaostatusurlsautorizadas': ['admin', 'superadmin'],
  'captacao.change_campoformulario': ['admin', 'superadmin'],
  'captacao.change_formulario': ['admin', 'superadmin'],
  'captacao.change_landingpage': ['admin', 'superadmin'],
  'captacao.change_landingpagestatus': ['admin', 'superadmin'],
  'captacao.change_mensagempadronizada': ['admin', 'superadmin'],
  'captacao.change_metas': ['admin', 'superadmin'],
  'captacao.change_tipomensagempadronizada': ['admin', 'superadmin'],
  'captacao.delete_botaosite': ['admin', 'superadmin'],
  'captacao.delete_botaostatusurlsautorizadas': ['admin', 'superadmin'],
  'captacao.delete_campoformulario': ['admin', 'superadmin'],
  'captacao.delete_formulario': ['admin', 'superadmin'],
  'captacao.delete_landingpage': ['admin', 'superadmin'],
  'captacao.delete_landingpagestatus': ['admin', 'superadmin'],
  'captacao.delete_mensagempadronizada': ['admin', 'superadmin'],
  'captacao.delete_metas': ['admin', 'superadmin'],
  'captacao.delete_tipomensagempadronizada': ['admin', 'superadmin'],
  'captacao.view_botaosite': [
    'Agente Pedag\u00f3gico',
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor de Marketing',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'Operador de Marketing',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'captacao.view_botaostatusurlsautorizadas': [
    'Agente Pedag\u00f3gico',
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor de Marketing',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'Operador de Marketing',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'captacao.view_campoformulario': [
    'Agente Pedag\u00f3gico',
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor de Marketing',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'Operador de Marketing',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'captacao.view_formulario': [
    'Agente Pedag\u00f3gico',
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor de Marketing',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'Operador de Marketing',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'captacao.view_landingpage': [
    'Agente Pedag\u00f3gico',
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor de Marketing',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'Operador de Marketing',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'captacao.view_landingpagestatus': [
    'Agente Pedag\u00f3gico',
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor de Marketing',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'Operador de Marketing',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'captacao.view_mensagempadronizada': [
    'Agente Pedag\u00f3gico',
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor de Marketing',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'Operador de Marketing',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'captacao.view_metas': [
    'Agente Pedag\u00f3gico',
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor de Marketing',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'Operador de Marketing',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'captacao.view_tipomensagempadronizada': [
    'Agente Pedag\u00f3gico',
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor de Marketing',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'Operador de Marketing',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'contenttypes.add_contenttype': ['admin', 'superadmin'],
  'contenttypes.change_contenttype': ['admin', 'superadmin'],
  'contenttypes.delete_contenttype': ['admin', 'superadmin'],
  'contenttypes.view_contenttype': ['admin', 'superadmin'],
  'crm.add_aluno': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.add_anotacao': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.add_campopersonalizado': [],
  'crm.add_camposescolhamultipla': [],
  'crm.add_camposescolhaunica': [],
  'crm.add_comoconheceuescola': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.add_escola': ['superadmin'],
  'crm.add_escoladestino': [],
  'crm.add_etapafunil': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.add_funil': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.add_historicointeracoes': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.add_jornada': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.add_jornadaetapa': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.add_motivochurn': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.add_origemlead': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.add_registroconversa': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.add_responsavel': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.add_tarefa': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.add_tipotarefa': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.add_turma': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.add_turno': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.add_usuarioescola': [],
  'crm.add_valorcampopersonalizado': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.change_aluno': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.change_anotacao': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.change_campopersonalizado': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.change_camposescolhamultipla': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.change_camposescolhaunica': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.change_comoconheceuescola': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.change_escola': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.change_escoladestino': [],
  'crm.change_etapafunil': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.change_funil': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.change_historicointeracoes': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.change_jornada': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.change_jornadaetapa': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.change_motivochurn': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.change_origemlead': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.change_registroconversa': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.change_responsavel': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.change_tarefa': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.change_tipotarefa': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.change_turma': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.change_turno': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.change_usuarioescola': [],
  'crm.change_valorcampopersonalizado': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.delete_aluno': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.delete_anotacao': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.delete_campopersonalizado': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.delete_camposescolhamultipla': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.delete_camposescolhaunica': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.delete_comoconheceuescola': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.delete_escola': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.delete_escoladestino': [],
  'crm.delete_etapafunil': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.delete_funil': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.delete_historicointeracoes': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.delete_jornada': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.delete_jornadaetapa': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.delete_motivochurn': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.delete_origemlead': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.delete_registroconversa': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.delete_responsavel': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.delete_tarefa': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.delete_tipotarefa': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.delete_turma': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.delete_turno': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.delete_usuarioescola': [],
  'crm.delete_valorcampopersonalizado': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.list_escolas': ['admin', 'superadmin'],
  'crm.manage_funil': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'admin',
    'gestor',
    'superadmin',
  ],
  'crm.view_aluno': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.view_anotacao': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.view_campopersonalizado': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.view_camposescolhamultipla': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.view_camposescolhaunica': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.view_comoconheceuescola': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.view_escola': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.view_escoladestino': [],
  'crm.view_etapafunil': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.view_funil': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.view_historicointeracoes': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.view_jornada': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.view_jornadaetapa': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.view_motivochurn': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.view_origemlead': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.view_registroconversa': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.view_responsavel': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.view_tarefa': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.view_tipotarefa': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.view_turma': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.view_turno': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'crm.view_usuarioescola': [],
  'crm.view_valorcampopersonalizado': [
    'Gestor de Capta\u00e7\u00e3o',
    'Gestor e Agente Pedag\u00f3gico',
    'Operador de Capta\u00e7\u00e3o',
    'admin',
    'gestor',
    'operador',
    'superadmin',
  ],
  'db.add_testmodel': ['admin', 'superadmin'],
  'db.change_testmodel': ['admin', 'superadmin'],
  'db.delete_testmodel': ['admin', 'superadmin'],
  'db.view_testmodel': ['admin', 'superadmin'],
  'django_celery_beat.add_clockedschedule': ['admin', 'superadmin'],
  'django_celery_beat.add_crontabschedule': ['admin', 'superadmin'],
  'django_celery_beat.add_intervalschedule': ['admin', 'superadmin'],
  'django_celery_beat.add_periodictask': ['admin', 'superadmin'],
  'django_celery_beat.add_periodictasks': ['admin', 'superadmin'],
  'django_celery_beat.add_solarschedule': ['admin', 'superadmin'],
  'django_celery_beat.change_clockedschedule': ['admin', 'superadmin'],
  'django_celery_beat.change_crontabschedule': ['admin', 'superadmin'],
  'django_celery_beat.change_intervalschedule': ['admin', 'superadmin'],
  'django_celery_beat.change_periodictask': ['admin', 'superadmin'],
  'django_celery_beat.change_periodictasks': ['admin', 'superadmin'],
  'django_celery_beat.change_solarschedule': ['admin', 'superadmin'],
  'django_celery_beat.delete_clockedschedule': ['admin', 'superadmin'],
  'django_celery_beat.delete_crontabschedule': ['admin', 'superadmin'],
  'django_celery_beat.delete_intervalschedule': ['admin', 'superadmin'],
  'django_celery_beat.delete_periodictask': ['admin', 'superadmin'],
  'django_celery_beat.delete_periodictasks': ['admin', 'superadmin'],
  'django_celery_beat.delete_solarschedule': ['admin', 'superadmin'],
  'django_celery_beat.view_clockedschedule': ['admin', 'superadmin'],
  'django_celery_beat.view_crontabschedule': ['admin', 'superadmin'],
  'django_celery_beat.view_intervalschedule': ['admin', 'superadmin'],
  'django_celery_beat.view_periodictask': ['admin', 'superadmin'],
  'django_celery_beat.view_periodictasks': ['admin', 'superadmin'],
  'django_celery_beat.view_solarschedule': ['admin', 'superadmin'],
  'django_celery_results.add_chordcounter': ['admin', 'superadmin'],
  'django_celery_results.add_groupresult': ['admin', 'superadmin'],
  'django_celery_results.add_taskresult': ['admin', 'superadmin'],
  'django_celery_results.change_chordcounter': ['admin', 'superadmin'],
  'django_celery_results.change_groupresult': ['admin', 'superadmin'],
  'django_celery_results.change_taskresult': ['admin', 'superadmin'],
  'django_celery_results.delete_chordcounter': ['admin', 'superadmin'],
  'django_celery_results.delete_groupresult': ['admin', 'superadmin'],
  'django_celery_results.delete_taskresult': ['admin', 'superadmin'],
  'django_celery_results.view_chordcounter': ['admin', 'superadmin'],
  'django_celery_results.view_groupresult': ['admin', 'superadmin'],
  'django_celery_results.view_taskresult': ['admin', 'superadmin'],
  'emails.add_email': ['admin', 'superadmin'],
  'emails.add_emailtemplate': [],
  'emails.change_email': ['admin', 'superadmin'],
  'emails.change_emailtemplate': [],
  'emails.delete_email': ['admin', 'superadmin'],
  'emails.delete_emailtemplate': [],
  'emails.view_email': ['admin', 'superadmin'],
  'emails.view_emailtemplate': [],
  'files.add_file': ['admin', 'superadmin'],
  'files.change_file': ['admin', 'superadmin'],
  'files.delete_file': ['admin', 'superadmin'],
  'files.view_file': ['admin', 'superadmin'],
  'historico.add_historicojornada': [],
  'historico.add_historicouser': [],
  'historico.change_historicojornada': [],
  'historico.change_historicouser': [],
  'historico.delete_historicojornada': [],
  'historico.delete_historicouser': [],
  'historico.view_historicojornada': [],
  'historico.view_historicouser': [],
  'kanban.add_etapa': [],
  'kanban.add_ticket': [],
  'kanban.change_etapa': [],
  'kanban.change_ticket': [],
  'kanban.delete_etapa': [],
  'kanban.delete_ticket': [],
  'kanban.view_etapa': [],
  'kanban.view_ticket': [],
  'logger.add_log': [],
  'logger.change_log': [],
  'logger.delete_log': [],
  'logger.view_log': [],
  'marketing.add_anexospedidomarketing': [
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Marketing',
    'Operador de Marketing',
    'admin',
    'superadmin',
  ],
  'marketing.add_campanha': ['Gestor de Marketing', 'admin', 'superadmin'],
  'marketing.add_pedidomarketing': [
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Marketing',
    'Operador de Marketing',
    'admin',
    'superadmin',
  ],
  'marketing.add_statuscadastradopedidomarketing': [
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Marketing',
    'Operador de Marketing',
    'admin',
    'superadmin',
  ],
  'marketing.add_statuspedidomarketing': [
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Marketing',
    'Operador de Marketing',
    'admin',
    'superadmin',
  ],
  'marketing.add_tarefamarketing': [],
  'marketing.change_anexospedidomarketing': [
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Marketing',
    'Operador de Marketing',
    'admin',
    'superadmin',
  ],
  'marketing.change_campanha': ['Gestor de Marketing', 'admin', 'superadmin'],
  'marketing.change_pedidomarketing': [
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Marketing',
    'Operador de Marketing',
    'admin',
    'superadmin',
  ],
  'marketing.change_statuscadastradopedidomarketing': [
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Marketing',
    'Operador de Marketing',
    'admin',
    'superadmin',
  ],
  'marketing.change_statuspedidomarketing': [
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Marketing',
    'Operador de Marketing',
    'admin',
    'superadmin',
  ],
  'marketing.change_tarefamarketing': [],
  'marketing.delete_anexospedidomarketing': [
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Marketing',
    'Operador de Marketing',
    'admin',
    'superadmin',
  ],
  'marketing.delete_campanha': ['Gestor de Marketing', 'admin', 'superadmin'],
  'marketing.delete_pedidomarketing': [
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Marketing',
    'Operador de Marketing',
    'admin',
    'superadmin',
  ],
  'marketing.delete_statuscadastradopedidomarketing': [
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Marketing',
    'Operador de Marketing',
    'admin',
    'superadmin',
  ],
  'marketing.delete_statuspedidomarketing': [
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Marketing',
    'Operador de Marketing',
    'admin',
    'superadmin',
  ],
  'marketing.delete_tarefamarketing': [],
  'marketing.view_anexospedidomarketing': [
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Marketing',
    'Operador de Marketing',
    'admin',
    'superadmin',
  ],
  'marketing.view_campanha': [
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Marketing',
    'Operador de Marketing',
    'admin',
    'superadmin',
  ],
  'marketing.view_kanban': [
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Marketing',
    'admin',
    'superadmin',
  ],
  'marketing.view_pedidomarketing': [
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Marketing',
    'Operador de Marketing',
    'admin',
    'superadmin',
  ],
  'marketing.view_statuscadastradopedidomarketing': [
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Marketing',
    'Operador de Marketing',
    'admin',
    'superadmin',
  ],
  'marketing.view_statuspedidomarketing': [
    'Agente Pedag\u00f3gico de Marketing',
    'Gestor de Marketing',
    'Operador de Marketing',
    'admin',
    'superadmin',
  ],
  'marketing.view_tarefamarketing': [],
  'notifications.add_notification': [],
  'notifications.add_notificationxuserconfig': [],
  'notifications.add_usernotificationconfig': [],
  'notifications.change_notification': [],
  'notifications.change_notificationxuserconfig': [],
  'notifications.change_usernotificationconfig': [],
  'notifications.delete_notification': [],
  'notifications.delete_notificationxuserconfig': [],
  'notifications.delete_usernotificationconfig': [],
  'notifications.view_notification': [],
  'notifications.view_notificationxuserconfig': [],
  'notifications.view_usernotificationconfig': [],
  'sessions.add_session': ['admin', 'superadmin'],
  'sessions.change_session': ['admin', 'superadmin'],
  'sessions.delete_session': ['admin', 'superadmin'],
  'sessions.view_session': ['admin', 'superadmin'],
  'testing_examples.add_roster': ['admin', 'superadmin'],
  'testing_examples.add_school': ['admin', 'superadmin'],
  'testing_examples.add_schoolcourse': ['admin', 'superadmin'],
  'testing_examples.add_student': ['admin', 'superadmin'],
  'testing_examples.change_roster': ['admin', 'superadmin'],
  'testing_examples.change_school': ['admin', 'superadmin'],
  'testing_examples.change_schoolcourse': ['admin', 'superadmin'],
  'testing_examples.change_student': ['admin', 'superadmin'],
  'testing_examples.delete_roster': ['admin', 'superadmin'],
  'testing_examples.delete_school': ['admin', 'superadmin'],
  'testing_examples.delete_schoolcourse': ['admin', 'superadmin'],
  'testing_examples.delete_student': ['admin', 'superadmin'],
  'testing_examples.view_roster': ['admin', 'superadmin'],
  'testing_examples.view_school': ['admin', 'superadmin'],
  'testing_examples.view_schoolcourse': ['admin', 'superadmin'],
  'testing_examples.view_student': ['admin', 'superadmin'],
  'token_blacklist.add_blacklistedtoken': ['admin', 'superadmin'],
  'token_blacklist.add_outstandingtoken': ['admin', 'superadmin'],
  'token_blacklist.change_blacklistedtoken': ['admin', 'superadmin'],
  'token_blacklist.change_outstandingtoken': ['admin', 'superadmin'],
  'token_blacklist.delete_blacklistedtoken': ['admin', 'superadmin'],
  'token_blacklist.delete_outstandingtoken': ['admin', 'superadmin'],
  'token_blacklist.view_blacklistedtoken': ['admin', 'superadmin'],
  'token_blacklist.view_outstandingtoken': ['admin', 'superadmin'],
  'users.add_baseuser': ['superadmin'],
  'users.add_invite': ['admin', 'superadmin'],
  'users.change_baseuser': ['admin', 'superadmin'],
  'users.change_invite': ['admin', 'superadmin'],
  'users.delete_baseuser': ['admin', 'superadmin'],
  'users.delete_invite': [],
  'users.list_baseuser': ['admin', 'superadmin'],
  'users.list_invite': ['admin', 'superadmin'],
  'users.update_user_role': ['admin', 'superadmin'],
  'users.update_user_status': ['superadmin'],
  'users.view_baseuser': ['admin', 'superadmin'],
  'users.view_invite': ['admin', 'superadmin'],
  'whatsapp.add_chatbot': [],
  'whatsapp.add_contato': [],
  'whatsapp.add_conversation': [],
  'whatsapp.add_diadisponivel': [],
  'whatsapp.add_horarioatendimento': [],
  'whatsapp.add_horariodisponivel': [],
  'whatsapp.add_message': [],
  'whatsapp.add_userwhatsappauth': [],
  'whatsapp.add_userwhatsappphone': [],
  'whatsapp.add_whatsappauth': [],
  'whatsapp.add_whatsappbusinessaccount': [],
  'whatsapp.add_whatsappphone': [],
  'whatsapp.change_chatbot': [],
  'whatsapp.change_contato': [],
  'whatsapp.change_conversation': [],
  'whatsapp.change_diadisponivel': [],
  'whatsapp.change_horarioatendimento': [],
  'whatsapp.change_horariodisponivel': [],
  'whatsapp.change_message': [],
  'whatsapp.change_userwhatsappauth': [],
  'whatsapp.change_userwhatsappphone': [],
  'whatsapp.change_whatsappauth': [],
  'whatsapp.change_whatsappbusinessaccount': [],
  'whatsapp.change_whatsappphone': [],
  'whatsapp.delete_chatbot': [],
  'whatsapp.delete_contato': [],
  'whatsapp.delete_conversation': [],
  'whatsapp.delete_diadisponivel': [],
  'whatsapp.delete_horarioatendimento': [],
  'whatsapp.delete_horariodisponivel': [],
  'whatsapp.delete_message': [],
  'whatsapp.delete_userwhatsappauth': [],
  'whatsapp.delete_userwhatsappphone': [],
  'whatsapp.delete_whatsappauth': [],
  'whatsapp.delete_whatsappbusinessaccount': [],
  'whatsapp.delete_whatsappphone': [],
  'whatsapp.view_chatbot': [],
  'whatsapp.view_contato': [],
  'whatsapp.view_conversation': [],
  'whatsapp.view_diadisponivel': [],
  'whatsapp.view_horarioatendimento': [],
  'whatsapp.view_horariodisponivel': [],
  'whatsapp.view_message': [],
  'whatsapp.view_userwhatsappauth': [],
  'whatsapp.view_userwhatsappphone': [],
  'whatsapp.view_whatsappauth': [],
  'whatsapp.view_whatsappbusinessaccount': [],
  'whatsapp.view_whatsappphone': [],
};
